<template>
  <b-card>
    <!-- Modal -->
    <b-modal
      modal-class="oderDetail"
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Close"
      header-close-content=""
      ok-only
    >
      <b-container>
        <div class="text-center" v-if="isLoading">
          <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
        <div v-else class="d-flex flex-column justify-content-between w-100 oderDetailBody">
          <div class="oderDetailContent">
            <b-card class="section-body">
              <!-- {{ viewItems }} -->

              <div class="ticketItemContent" v-for="(item, index) in viewItems" :key="index">
                <div class="transaction-info">
                  <div class="transaction-info-row d-flex">
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Display Ticket Id</div>
                      <div class="item-value">#{{ item.display_ticket_id }}</div>
                    </div>
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Status</div>
                      <div class="item-value">
                        {{ item.status === 1 ? 'Success' : 'Fail' }}
                      </div>
                    </div>
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Notice</div>
                      <div class="item-value">
                        {{ item.notice }}
                      </div>
                    </div>
                  </div>
                  <div class="transaction-info-row d-flex">
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Dispatched At</div>
                      <div class="item-value">
                        {{ item.dispatched_at }}
                      </div>
                    </div>
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Printed At</div>
                      <div class="item-value">
                        {{ item.printed_at }}
                      </div>
                    </div>
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Barcode</div>
                      <div class="item-value">
                        {{ item.barcode }}
                      </div>
                    </div>
                  </div>
                  <div class="transaction-info-row d-flex">
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Full Serial</div>
                      <div class="item-value">
                        {{ item.full_serial }}
                      </div>
                    </div>
                    <div class="transaction-info-item f-1">
                      <div class="item-title">Last Four Serial</div>
                      <div class="item-value">
                        {{ item.last_four_serial }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button v-b-toggle :href="`#img_${item.display_ticket_id}`" @click.prevent class="myToggleBtn">
                        <p class="font-15">Image</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="`img_${item.display_ticket_id}`"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="transaction-info-item f-1">
                          <!-- <div class="item-title">Image</div> -->
                          <div class="item-value">
                            <img
                              :src="item.image_preview[0].src"
                              style="height: auto; width: 100%"
                              @click="openGallery(item.id)"
                              alt="image"
                            />
                            <LightBox
                              :ref="'lightbox_' + item.id"
                              :media="item.image_preview"
                              :show-light-box="false"
                              :show-thumbs="false"
                            ></LightBox>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
                <div class="accordion">
                  <b-card no-body class="mb-lg-1 mb-3 myToggle">
                    <b-card-header header-tag="header" class="" role="tab">
                      <b-button v-b-toggle :href="`#${item.display_ticket_id}`" @click.prevent class="myToggleBtn">
                        <p class="font-15">Order Detail</p>
                        <p class="btnChooseMoney"></p>
                      </b-button>
                    </b-card-header>
                    <b-collapse
                      :id="item.display_ticket_id"
                      accordion="my-accordion"
                      role="tabpanel"
                      class="myToggleContent"
                    >
                      <b-card-body>
                        <div class="receiver-info d-flex" v-for="(order, index) in item.order_details" :key="index">
                          <div class="ticket-item w-100">
                            <div class="ticket-detail">
                              <div class="ticket-name">
                                {{ order.ticket_name }}
                              </div>
                              <div class="product-name pr-3">
                                {{ order.period_name }}
                              </div>
                              <div class="product-name">
                                {{ order.game.name }}
                              </div>
                              <span class="ticket-price float-right correct"
                                >{{ formatPrice(order.price) }}&nbsp;<img
                                  style="position: relative; top: -2px"
                                  alt="image"
                              /></span>
                              <span class="ticket-price float-right correct"
                                >{{ order.product.name + ' | ' }}&nbsp;<img
                                  style="position: relative; top: -2px"
                                  alt="image"
                              /></span>
                            </div>
                            <div
                              class="ticket-result d-flex flex-wrap"
                              v-if="!ticketStatus.includes(order.ticket_info)"
                            >
                              <div
                                :class="{
                                  ticketItemListNumber: true
                                }"
                                v-for="(number, index) in JSON.parse(order.ticket_info)"
                                :key="index"
                              >
                                {{ number }}
                              </div>
                            </div>
                            <div class="ticket-result d-flex flex-wrap" v-else>
                              <div>
                                {{ order.ticket_info }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </b-card-body>
                    </b-collapse>
                  </b-card>
                </div>
              </div>
            </b-card>
          </div>
        </div>
      </b-container>
    </b-modal>
    <!-- Header -->
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Ticket Satistics List</h4>
      </b-col>
      <!-- Filter Game -->
      <b-col>
        <b-form-group id="input-screen-filter" label="Game" label-for="screen-filter">
          <b-form-select id="screen-filter" v-model="filter_game" :options="allGameAdsList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </b-form-select>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_from_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>
      <!-- filter date to -->
      <b-col>
        <b-form-group id="input-from-date" label="From Date:" label-for="from-date">
          <datePicker v-model="filter_to_date" :config="dpOptions" @dp-change="fetchData"></datePicker>
        </b-form-group>
      </b-col>
    </b-row>
    <!-- Table -->
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(game)="item">
        {{ item.item.game.name }}
      </template>
      <template v-slot:cell(total_money)="item">
        {{ formatPrice(item.item.total_money) }}
      </template>
      <template v-slot:cell(total_rejected_money)="item">
        {{ formatPrice(item.item.total_rejected_money) }}
      </template>
      <template v-slot:cell(total_success_money)="item">
        {{ formatPrice(item.item.total_success_money) }}
      </template>
      <template v-slot:cell(status)="item" :v-if="item.value === 1">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="danger">Inactive</b-badge>
        <b-badge class="mr-1" v-else variant="warning">Pending</b-badge>
      </template>
      <template v-slot:cell(actions)="item">
        <v-icon class="text-success" @click="viewItem(item.item)">mdi-eye</v-icon>
      </template>
    </b-table>
    <!-- Footer -->
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import ApiService from '@/common/api.service.js';
import _ from 'lodash';
import axios from 'axios';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import LightBox from 'vue-image-lightbox';
import { formatDateForView } from '@/utils/time.js';

export default {
  name: 'Ticket Statistics',
  components: { datePicker, LightBox },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      filter_name: '',
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Deleted' }
      ],
      headers: [
        { text: 'Game', key: 'game' },
        { text: 'period', key: 'period' },
        { text: 'lottery_time', key: 'lottery_time' },
        { text: 'total_tickets', key: 'total_tickets' },
        { text: 'total_failed_tickets', key: 'total_failed_tickets' },
        { text: 'total_success_tickets', key: 'total_success_tickets' },
        { text: 'total_money', key: 'total_money' },
        { text: 'total_rejected_money', key: 'total_rejected_money' },
        { text: 'total_success_money', key: 'total_success_money' },
        { text: 'Status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data, filter
      items: [],
      allGameAdsList: [],
      filter_game: null,
      selectedGame: null,
      filter_from_date: '',
      filter_to_date: '',
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        game_id: '',
        period_no: '',
        lottery_time: '',
        total_tickets: '',
        total_failed_tickets: '',
        total_rejected_tickets: '',
        total_success_tickets: '',
        total_money: '',
        total_failed_money: '',
        total_rejected_money: '',
        total_success_money: '',
        status: null,
        game: {
          name: ''
        },
        period: ''
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'MM/DD/YYYY',
        sideBySide: true
      },
      viewItems: [],
      orderDetail: [],
      ticket_info: [],
      image_preview: []
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.name ||
        this.errors.has('code') ||
        !this.editedItem.code ||
        !this.editedItem.index ||
        this.errors.has('index')
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: 'Ticket Satistics List' }]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const allGame = ApiService.get('allgame');
      const ticketStatistics = ApiService.get(
        'ticket-statistics',
        `?&game=${this.filter_game || ''}&status=${this.filter_status == null ? '' : this.filter_status}&from=${
          this.filter_from_date || ''
        }&to=${this.filter_to_date || ''}&limit=${limit}&offset=${offset}`
      );
      axios
        .all([ticketStatistics, allGame])
        .then(
          axios.spread((...response) => {
            const ticketStatisticsRes = response[0];
            const allGameRes = response[1];
            this.items = ticketStatisticsRes.data.data.ticketStatisticList.map((ads) => {
              return {
                ...ads,
                lottery_time: formatDateForView(new Date(ads.lottery_time).toISOString())
              };
            });
            this.rows = ticketStatisticsRes.data.data.total;
            this.allGameAdsList = allGameRes.data.data;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.$bvToast.toast(`${errors}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    viewItem: function (item) {
      this.dialog = true;
      this.modalTitle = 'Update Ticket Satistics';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.isLoading = true;
      // this.filter_status = item.status;
      ApiService.get('ticket-statistics', `${this.editedItem.id}`)
        .then((resp) => {
          if (resp.status === 200) {
            this.viewItems = resp.data.data;
            this.viewItems = resp.data.data.map((ads) => {
              return {
                ...ads,
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
              };
            });
            this.isLoading = false;
          }
        })
        .catch((err) => {
          this.$bvToast.toast(`${err}`, {
            title: null,
            variant: 'danger',
            autoHideDelay: 3000,
            noCloseButton: true
          });
          this.isBusy = false;
        });
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        ApiService.delete(`ticket-statistics/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.$bvToast.toast('Ticket Satistics deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.$bvToast.toast(`${errors}`, {
              title: null,
              variant: 'danger',
              autoHideDelay: 3000,
              noCloseButton: true
            });
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = {
        id: '',
        name: '',
        code: '',
        index: '',
        status: ''
      };
      this.viewItems = [];
      this.orderDetail = [];
      this.ticket_info = [];
      this.editedIndex = -1;
      this.filter_status = null;
    }
  }
};
</script>
